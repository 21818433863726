<template>
  <b-row>
    <b-col sm="12" md="12" class="mt-1">
      <b-form @submit.prevent>
        <b-card>
          <b-card-body>
            <div class="mt-1">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                @click="changetype(0)"
                :variant="appData.type == 0 ? 'primary' : 'flat-primary'"
              >
                {{ $t("fields.publicprovidentfund") }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                @click="changetype(1)"
                :variant="appData.type == 1 ? 'primary' : 'flat-primary'"
              >
                {{ $t("fields.employeeprovidentfund") }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                @click="changetype(2)"
                :variant="appData.type == 2 ? 'primary' : 'flat-primary'"
              >
                {{ $t("fields.governmentprovidentfund") }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                @click="changetype(3)"
                :variant="appData.type == 3 ? 'primary' : 'flat-primary'"
              >
                {{ $t("fields.gratuity") }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                @click="changetype(4)"
                :variant="appData.type == 4 ? 'primary' : 'flat-primary'"
              >
                {{ $t("navigation.nps") }}
              </b-button>
            </div>
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormObserver"
            >
              <b-form
                @submit.prevent="handleSubmit(onSubmit)"
                @reset.prevent="resetForm"
              >
                <b-row>
                  <b-col sm="12" md="6" class="mt-1">
                    <label> {{ $t("fields.holdername") }}</label>
                    <inputvselect
                      name="holdername"
                      :label="(reldata) => reldata.name"
                      :valuex="appData.holdernameid"
                      keys="key"
                      @updatedata="(val) => updatedetails('holdernameid', val)"
                      :options="familymembers"
                      id="holdername"
                      validations="required"
                    ></inputvselect>
                  </b-col>
                  <!-- ////////Bank details/////////////// -->
                  <b-col sm="12" md="6" v-if="appData.type == 0">
                    <b-row>
                      <b-col sm="9">
                        <inputtext
                          class="mt-1"
                          name="IFSC Code"
                          :tooltip="$t('navigation.ifsccodesearch')"
                          :valuex="appData.ifsccode"
                          keys="key"
                          @updatedata="(val) => (appData.ifsccode = val)"
                          :placeholder="
                            $t('fields.enter') + ' ' + $t('fields.ifsccode')
                          "
                          :label="$t('fields.ifsccode')"
                          validations="required|length:11"
                        ></inputtext>
                      </b-col>
                      <b-col sm="3" class="mt-1">
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          class="mt-2 w-100"
                          variant="primary"
                          @click="getifsc"
                        >
                          <feather-icon icon="SearchIcon" class="mr-25" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="appData.bankname && appData.type == 0"
                  >
                    <h6>{{ $t("fields.bankname") }}</h6>
                    {{ appData.bankname }}
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="appData.branch && appData.type == 0"
                  >
                    <h6>{{ $t("fields.branch") }}</h6>
                    {{ appData.branch }}
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="appData.address && appData.type == 0"
                  >
                    <h6>{{ $t("fields.bankaddress") }}</h6>
                    {{ appData.address }}
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="appData.state && appData.type == 0"
                  >
                    <h6>{{ $t("fields.state") }}</h6>
                    {{ appData.state }}
                  </b-col>

                  <!-- ///////////////////////// -->
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="[1, 2].includes(appData.type)"
                  >
                    <label> {{ $t("fields.trustorgoverment") }}</label>
                    <inputselect
                      name="trustorgoverment"
                      :tooltip="
                        $t('fields.select') +
                        ' ' +
                        $t('fields.trustorgoverment')
                      "
                      :placeholder="
                        $t('fields.select') +
                        ' ' +
                        $t('fields.trustorgoverment')
                      "
                      :valuex="appData.trustorgoverment"
                      keys="key"
                      @updatedata="(val) => (appData.trustorgoverment = val)"
                      :options="['Government', 'Trust']"
                      id="trustorgoverment"
                      validations="required"
                    ></inputselect>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="[1, 2].includes(appData.type)"
                  >
                    <inputtext
                      name="uannumber"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.uannumber')
                      "
                      :label="$t('fields.uannumber')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.uannumber')
                      "
                      :valuex="appData.uannumber"
                      @updatedata="(val) => (appData.uannumber = val)"
                      validations="required|integer"
                    ></inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1" v-if="appData.type == 4">
                    <inputtext
                      name="schemename"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.schemename')
                      "
                      :label="$t('fields.schemename')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.schemename')
                      "
                      :valuex="appData.schemename"
                      @updatedata="(val) => (appData.schemename = val)"
                      validations="required"
                    ></inputtext>
                  </b-col>

                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="[1, 2].includes(appData.type)"
                  >
                    <inputtext
                      name="pfnumber"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.pfnumber')
                      "
                      :label="$t('fields.pfnumber')"
                      :valuex="appData.pfnumber"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.pfnumber')
                      "
                      @updatedata="(val) => (appData.pfnumber = val)"
                      validations="required"
                    >
                    </inputtext>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="[3].includes(appData.type)"
                  >
                    <label> {{ $t("fields.dateofjoin") }}</label>
                    <flat-pickr
                      v-model="appData.dateofjoin"
                      :config="{ dateFormat: 'd-m-Y' }"
                      placeholder="DD-MM-YYYY"
                      class="form-control"
                    />
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="[1, 2, 3].includes(appData.type)"
                  >
                    <inputtext
                      name="basicby"
                      :tooltip="$t('fields.enter') + ' ' + $t('fields.basicby')"
                      :label="$t('fields.basicby')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.basicby')
                      "
                      :valuex="appData.basicby"
                      @updatedata="(val) => updatevalue(0, val)"
                      validations="integer"
                    >
                    </inputtext>
                  </b-col>

                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="[1, 2, 3].includes(appData.type)"
                  >
                    <inputtext
                      name="dearnessallowance"
                      :tooltip="
                        $t('fields.enter') +
                        ' ' +
                        $t('fields.dearnessallowance')
                      "
                      :label="$t('fields.dearnessallowance')"
                      :placeholder="
                        $t('fields.enter') +
                        ' ' +
                        $t('fields.dearnessallowance')
                      "
                      :valuex="appData.dearnessallowance"
                      @updatedata="(val) => updatevalue(1, val)"
                      validations="integer"
                    ></inputtext>
                  </b-col>
                  <b-col sm="12" md="6" v-if="appData.type == 0" class="mt-1">
                    <inputtext
                      name="accountnumber"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.accountnumber')
                      "
                      :label="$t('fields.accountnumber')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.accountnumber')
                      "
                      :valuex="appData.accountnumber"
                      @updatedata="(val) => (appData.accountnumber = val)"
                      validations="required|integer|length:12"
                    ></inputtext>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="[0, 4].includes(appData.type)"
                  >
                    <inputtext
                      name="openingbalanceunit"
                      :tooltip="
                        $t('fields.enter') +
                        ' ' +
                        $t('fields.openingbalanceunit')
                      "
                      :label="$t('fields.openingbalanceunit')"
                      :valuex="appData.openingbalance"
                      @updatedata="
                        (val) => managefloat('openingbalance', parseFloat(val))
                      "
                      validations="required|amount"
                    >
                    </inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1" v-if="appData.type == 4">
                    <inputtext
                      name="nav"
                      :tooltip="$t('fields.enter') + ' ' + $t('fields.nav')"
                      :label="$t('fields.nav')"
                      :placeholder="$t('fields.enter') + ' ' + $t('fields.nav')"
                      :valuex="appData.nav"
                      @updatedata="(val) => updatevaluenps(1, val)"
                      validations="integer"
                    ></inputtext>
                  </b-col>
                  <!-- <b-col sm="12" md="6" class="mt-1" v-if="appData.type == 1">
                    <label> {{ $t("fields.investmentcycle") }}</label>
                    <inputselect
                      name="investmentcycle"
                      :tooltip="
                        $t('fields.select') + ' ' + $t('fields.investmentcycle')
                      "
                      :placeholder="
                        $t('fields.select') + ' ' + $t('fields.investmentcycle')
                      "
                      :valuex="appData.investmentcycle"
                      keys="key"
                      @updatedata="(val) => (appData.investmentcycle = val)"
                      :options="[
                        'Monthly',
                        'Quaterly',
                        'Half Yearly',
                        'Annuvally',
                        'Yearly',
                        'One Time',
                      ]"
                      id="investmentcycle"
                      validations="required"
                    ></inputselect>
                  </b-col> -->
                  <b-col sm="12" md="6" class="mt-1" v-if="appData.type == 3">
                    <inputtext
                      name="yearsofserv"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.yearsofserv')
                      "
                      :label="$t('fields.yearsofserv')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.yearsofserv')
                      "
                      :valuex="appData.yearsofserv"
                      @updatedata="(val) => updatevalue(2, val)"
                      validations="integer"
                    >
                    </inputtext>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="[1, 2].includes(appData.type)"
                  >
                    <inputcleave
                      :cleave="{
                        date: true,
                        datePattern: ['d'],
                      }"
                      name="salareydate"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.salareydate')
                      "
                      :label="$t('fields.salareydate')"
                      placeholder="DD"
                      :valuex="appData.salareydate"
                      @updatedata="(val) => (appData.salareydate = val)"
                    >
                    </inputcleave>
                  </b-col>
                  <!--
                  <b-col sm="12" md="6" class="mt-1" v-if="appData.type == 4">
                    <inputtext
                      name="totalunits"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.totalunits')
                      "
                      :label="$t('fields.totalunits')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.totalunits')
                      "
                      :valuex="appData.totalunits"
                      @updatedata="(val) => updatevaluenps(0, val)"
                      validations="integer"
                    ></inputtext>
                  </b-col> -->
                  <b-col sm="12" md="6" class="mt-1" v-if="appData.type == 4">
                    <label> {{ $t("fields.value") }}</label>
                    <br />
                    <span
                      style="font-family: rupee; font-size: 17px"
                      class="fw-bolder"
                      >&#8377;</span
                    >
                    {{ appData.npsvalue }}
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="[3].includes(appData.type)"
                  >
                    <label> {{ $t("fields.value") }}</label>
                    <br />
                    <span
                      style="font-family: rupee; font-size: 17px"
                      class="fw-bolder"
                      >&#8377;</span
                    >
                    {{ appData.value }}
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1">
                    <label> {{ $t("fields.nominee") }}</label>

                    <inputvselect
                      name="nominee"
                      :label="(reldata) => reldata.name"
                      :valuex="appData.nomineeid"
                      keys="key"
                      @updatedata="(val) => updatedetails('nomineeid', val)"
                      :options="nomineedetails"
                      id="nomineeid"
                      validations="required"
                    ></inputvselect>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="remarks"
                      :tooltip="$t('fields.enter') + ' ' + $t('fields.remarks')"
                      :label="$t('fields.remarks')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.remarks')
                      "
                      :valuex="appData.remarks"
                      @updatedata="(val) => (appData.remarks = val)"
                      validations=""
                    >
                    </inputtext>
                  </b-col>
                  <!-- <b-col sm="12" v-if="appData.type == 0" md="12" class="mt-1">
                    <hr />
                  </b-col>
                  <b-col sm="9" v-if="appData.type == 0">
                    <inputtext
                      class="mt-1"
                      name="IFSC Code"
                      :tooltip="$t('navigation.ifsccodesearch')
                        "
                      :valuex="appData.ifsccode"
                      keys="key"
                      @updatedata="(val) => (appData.ifsccode = val)"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.ifsccode')
                      "
                      :label="$t('fields.ifsccode')"
                      validations="required|length:11"
                    ></inputtext>
                    <span class="text-muted mt-0">
                      {{ $t("fields.ifcscodefill") }}
                    </span>
                  </b-col>
                  <b-col sm="3" v-if="appData.type == 0" class="mt-1">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      class="mt-2 w-100"
                      variant="primary"
                      @click="getifsc"
                    >
                      <feather-icon icon="SearchIcon" class="mr-25" />
                      <span class="align-middle">
                        {{ $t("fields.searchifsc") }}</span
                      >
                    </b-button>
                  </b-col> -->

                  <!-- <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="appData.bankname && appData.type == 0"
                  >
                    <h6>{{ $t("fields.bankname") }}</h6>
                    {{ appData.bankname }}
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="appData.branch && appData.type == 0"
                  >
                    <h6>{{ $t("fields.branch") }}</h6>
                    {{ appData.branch }}
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="appData.address && appData.type == 0"
                  >
                    <h6>{{ $t("fields.bankaddress") }}</h6>
                    {{ appData.address }}
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="appData.state && appData.type == 0"
                  >
                    <h6>{{ $t("fields.state") }}</h6>
                    {{ appData.state }}
                  </b-col> -->

                  <b-col sm="12" v-if="edittype == 0">
                    <h5 class="mt-2">{{ $t("fields.fileupload") }}</h5>
                    <b-row>
                      <b-col sm="12" md="6" class="mt-1" lg="6">
                        <validation-provider
                          #default="validationContext"
                          name="Upload File"
                          rules=""
                        >
                          <b-form-group
                            :label="$t('fields.uploadfile')"
                            label-for="fileinput"
                          >
                            <b-form-file
                              accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf"
                              id="fileinput"
                              name="Upload File"
                              :placeholder="$t('fields.uploadfile')"
                              drop-placeholder="Drop file here..."
                              @change="updateValue($event)"
                            />
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col sm="12">
                    <div class="d-flex float-right">
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        class="mr-1"
                        variant="secondary"
                        @click="$router.go(-1)"
                      >
                        <feather-icon icon="ArrowLeftIcon" class="mr-25" />
                        <span class="align-middle"
                          >{{ $t("fields.back") }}
                        </span>
                      </b-button>
                      <b-button
                        v-if="edittype == 1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-1"
                        :to="{
                          name: GENAPP_APP_STORE_MODULE_NAME + '-view',
                          params: { id: iddata },
                        }"
                      >
                        <feather-icon icon="EyeIcon" class="mr-25" />
                        {{ $t("fields.view") }}
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary                            "
                        class="mr-1"
                        type="submit"
                      >
                        <feather-icon icon="Edit3Icon" class="mr-25" />
                        {{ $t("fields.save") }}
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
              <!--/ form -->
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-form>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BFormDatepicker,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormFile,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import inputvselect from "@/views/Component/Input/inputvselect.vue";
import inputselect from "@/views/Component/Input/inputselect.vue";
import inputtext from "@/views/Component/Input/inputtext.vue";
import inputcleave from "@/views/Component/Input/inputcleave.vue";

import inputtextarea from "@/views/Component/Input/inputtextarea.vue";
import { required, alphaNum } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import StoreModule from "./StoreModule";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import axios from "axios";

export default {
  components: {
    BCol,
    BCard,
    BRow,
    BForm,
    flatPickr,
    BCardBody,
    BFormGroup,
    inputselect,
    inputvselect,
    inputtextarea,
    axios,
    inputcleave,
    inputtext,
    BFormCheckbox,
    BFormInput,
    BFormFile,
    BFormInvalidFeedback,
    BButton,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
    };
  },
  setup(props, { emit }) {
    const GENAPP_APP_STORE_MODULE_NAME = "financialassets-retirals";
    const modulename = "Retirals";
    const modulenamesub = "retirals";
    // Register module
    if (!store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
      store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
    const toast = useToast();
    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });

    let today = new Date();

    let key = 0;
    let edittype = 0;
    let storetype = "addData";
    let iddata = router.currentRoute.params.id;
    if (iddata != undefined) {
      edittype = 1;
      storetype = "editData";
    }
    const blankAppData = {
      type: 0,
      name: "",
      ifsccode: "",
      bankname: "",
      branch: "",
      address: "",
      state: "",
      holdernameid: "",
      holdername: "",
      openingbalance: 0,
      schemename: "",
      nomineename: "",
      nomineeid: "",
      accountnumber: "",
      nav: "",
      npsvalue: 0,
      pfnumber: "",
      salareydate: "",
      trustorgoverment: "",
      basicby: "",
      uannumber: "",
      dearnessallowance: "",
      yearsofserv: "",
      value: 0,
      dateofjoin: "",
      remarks: "",
      fileData: {
        name: "",
        file: "",
        size: "",
        type: "",
      },
    };
    const appData = ref(JSON.parse(JSON.stringify(blankAppData)));
    var tempcatch = 1;

    const onSubmit = () => {
      let temp = appData.value;
      let formData = new FormData();

      let appdatax = {
        customvalue: temp.customcategory != "Others" ? 0 : 1,
        type: temp.type,
        openingbalance: temp.openingbalance,
        holdername: temp.holdername,
        name: temp.name,
        holdernameid: temp.holdernameid,
        // investmentcycle: temp.investmentcycle,
        dearnessallowance: temp.dearnessallowance,
        salareydate: temp.salareydate,
        accountnumber: temp.accountnumber,
        nomineename: temp.nomineename,
        nav: temp.nav,
        nomineeid: temp.nomineeid,
        npsvalue: temp.npsvalue,
        schemename: temp.schemename,
        trustorgoverment: temp.trustorgoverment,
        basicby: temp.basicby,
        yearsofserv: temp.yearsofserv,
        pfnumber: temp.pfnumber,
        uannumber: temp.uannumber,
        dateofjoin: temp.dateofjoin,
        ifsccode: temp.ifsccode,
        address: temp.address,
        bankname: temp.bankname,
        branch: temp.branch,
        state: temp.state,
        remarks: temp.remarks,
        value: temp.value,
      };
      let metadataappdatax = {
        customvalue: temp.customcategory != "Others" ? 0 : 1,
        type: temp.type,
        ifsccode: temp.ifsccode,
        holdername: temp.holdername,
        name: temp.name,
        nomineeid: temp.nomineeid,
        schemename: temp.schemename,
        trustorgoverment: temp.trustorgoverment,
        dateofjoin: temp.dateofjoin
          ? temp.dateofjoin.split("-").reverse().join("-")
          : null,
        salareydate: temp.salareydate
          ? temp.salareydate.split("-").reverse().join("-")
          : null,
        npsvalue: temp.npsvalue,
        investmentcyc: temp.investmentcyc,
        value: temp.value,
        openingbalance: temp.openingbalance,
        holdernameid: temp.holdernameid,
      };

      if (edittype == 1) {
        var jx = {
          id: router.currentRoute.params.id || null,
          customvalue: metadataappdatax.customvalue,
          data: appdatax,
          name: temp.name,
          metadata: metadataappdatax,
        };
      } else {
        formData.append("name", temp.name);
        formData.append("customvalue", metadataappdatax.customvalue);
        formData.append("data", JSON.stringify(appdatax));
        formData.append("metadata", JSON.stringify(metadataappdatax));

        if (temp.fileData.file) {
          let file = temp.fileData.file;
          formData.append("file", file);
        }
      }
      if (tempcatch) {
        tempcatch = 0;
        store
          .dispatch(
            GENAPP_APP_STORE_MODULE_NAME + "/" + storetype,
            edittype == 0 ? formData : jx
          )
          .then((response) => {
            if (response.data.success) {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  text:
                    modulename +
                    (edittype == 1 ? " editted" : " added") +
                    " successfully",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              router
                .push({
                  name: GENAPP_APP_STORE_MODULE_NAME,
                  params: {
                    id: response.data.id,
                  },
                })
                .catch(() => {});
            } else if (response.data.success == 0) {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  variant: "error",
                },
              });
              router
                .push({
                  name: GENAPP_APP_STORE_MODULE_NAME,
                  params: {
                    id: response.data.id,
                  },
                })
                .catch(() => {});
            }
          })
          .catch((error) => {
            tempcatch = 1;

            if (error.response.status === 404) {
              appData.value = undefined;
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  text: "Couldnt find " + modulenamesub + " details",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          });
      }
    };
    const familymembers = ref([]);
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchefamilymemberdetails")
      .then((response) => {
        familymembers.value = response.data.data;
      });

    const nomineedetails = ref([]);
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchenomineedetails")
      .then((response) => {
        nomineedetails.value = response.data.data;
      });
    if (iddata != undefined) {
      store
        .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchData", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          if (!response.data.data) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Couldnt find " + modulenamesub + " details",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            router
              .push({
                name: GENAPP_APP_STORE_MODULE_NAME,
              })
              .catch(() => {});
          }

          appData.value = response.data.data.data;
          appData.value.name = response.data.data.name;
          appData.value.investmentcycle = response.data.data.investmentcycle;
          appData.value.trustorgoverment = response.data.data.trustorgoverment;

          key = Math.random();
        })
        .catch((error) => {
          if (error.response.status === 404) {
            appData.value = undefined;
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Couldnt find " + modulenamesub + " details",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else if (error.response.status === 500) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Input fields missing values",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else if (error.response.status === 422) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Please refresh your page",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    }
    const resetappData = () => {
      appData.value = JSON.parse(JSON.stringify(blankAppData));
    };
    const trustgovt = ["Goverment", "Trust"];

    const propertytype = [
      "Primary Residence ",
      "Investment",
      "Vacation Home",
      "Rented",
      "Others",
    ];
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetappData);
    return {
      GENAPP_APP_STORE_MODULE_NAME,
      edittype,
      iddata,
      appData,
      modulename,
      modulenamesub,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      propertytype,
      key,
      familymembers,
      trustgovt,
      nomineedetails,
      tempcatch,
      today,
    };
  },
  mounted() {},
  methods: {
    updatedetails(tit, val) {
      if (tit == "holdernameid") {
        this.appData.holdernameid = val.id;
        this.appData.holdername = val.name;
      } else if (tit == "nomineeid") {
        this.appData.nomineeid = val.id;
        this.appData.nomineename = val.name;
      }
    },
    changetype(type) {
      this.appData.name = "";
      this.appData.bankname = "";
      this.appData.accountnumber = "";
      this.appData.ifsccode = "";
      this.appData.address = "";
      this.appData.branch = "";
      this.appData.state = "";
      this.appData.openningbalance = "";
      this.appData.nominee = "";
      this.appData.uannumber = "";
      this.appData.basicby = "";
      this.appData.investmentcycle = "";
      this.appData.dearnessallowance = "";
      this.appData.nominee = "";
      this.appData.type = type;
    },
    getifsc() {
      if (this.appData.ifsccode.length > 0) {
        axios
          .get(`https://ifsc.razorpay.com/` + this.appData.ifsccode)
          .then((response) => {
            this.appData.bankname = response.data.BANK;
            this.appData.branch = response.data.BRANCH;
            this.appData.address = response.data.ADDRESS;
            this.appData.state = response.data.STATE;
          })
          .catch((error) => {
            if (error.response.status == 404) {
              // tHAT DOES NOT WORK
              this.appData.bankname = "";
              this.appData.branch = "";
              this.appData.address = "";
              this.appData.state = "";

              this.$swal({
                icon: "error",
                title: "IFSC Not Found!",
                text: "Please validate your IFSC Code",
              });
            }
          });
      } else {
        this.$swal({
          icon: "error",
          title: "Missing Value!",
          text: "Enter a Valid IFSC Code",
        });
      }
    },
    updateValue(value) {
      if (value.target.files[0].size <= 2 * 1048576) {
        this.appData.fileData.file = value.target.files[0];
        this.appData.fileData.name = value.target.files[0].name;
        this.appData.fileData.size = value.target.files[0].size;
        this.appData.fileData.type = value.target.files[0].type;
      } else {
        this.$swal({
          icon: "error",
          title: "File value Reached!",
          text: "File size must be maximum 2 mb .",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }
    },
    managefloat(name, val) {
      this.$set(this.appData, name, parseFloat(val));
    },

    updatevaluenps(type, val) {
      if (type == 0) {
        this.appData.openingbalance = val;
      } else if (type == 1) {
        this.appData.nav = val;
      }
      this.appData.npsvalue =
        Math.round(this.appData.openingbalance * this.appData.nav * 1000) /
          1000 || 0;
    },
    updatevalue(type, val) {
      if (type == 0) {
        this.appData.basicby = val;
      } else if (type == 1) {
        this.appData.dearnessallowance = val;
      } else if (type == 2) {
        this.appData.yearsofserv = val;
      }
      this.appData.value =
        Math.round(
          ((parseFloat(this.appData.basicby) +
            parseFloat(this.appData.dearnessallowance)) /
            2) *
            parseFloat(this.appData.yearsofserv)
        ) || 0;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
